import * as Yup from 'yup'; 

export const ShiftsSchema = Yup.object().shape({   
    shiftname: Yup.string()
        .required('Please provide shift name'),
    shiftcodename: Yup.string()
        .required('Please provide shift code name'), 
    shiftstarttime: Yup.string()
        .required('Please provide shift start time'),
    shiftendtime: Yup.string()
        .required('Please provide shift end time'),
    breaktime: Yup.number() 
        .typeError('Please enter number only'),
});