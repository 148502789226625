/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CompanyAccessSetupRoute from './AccessSetup';
import EmployeeModuleSetupRoute from './Employee';
import LeaveTrackView from './Employee/LeaveTrackView';
import EmployeeAttendanceList from './Employee/attendanceList';
import LeaveLanding from './LeaveLanding';
import CompanyModuleSetupRoute from './ModuleSetup';
import DocumentRequest from './ModuleSetup/DocumentRequest/index';
import ModuleSetupLanding from './ModuleSetupPage/ModuleSetupLanding';
import EntitlementRoute from './MyEntitlements';
import Onboarding from './Onboarding';
import HireLanding from './Hire';
import OrganisationchartRoute from './Organisationchart';
import PayrollRoute from './Payroll';
import PerformanceRoute from './Performance';
import ProjectsRoute from './Project';
import RecognitionListIndex from './RecognitionList/RecognitionListIndex.jsx';
import RecognitionRoute from './RecognitionMain';
import ReportRoute from './Report';
import RosterLanding from './RosterLanding';
import CompanySettingModuleSetupRoute from './Settings';
import SupportModuleSetupRoute from './Support';
import SurveySetupRoute from './Survey';
import TasksForm from './Tasks/form.jsx';
import Rewards from './Rewards';
import TasksLanding from './TasksLanding';
import TeamApprovalSetupRoute from './TeamApproval';
import TimesheetRoute from './Timesheet';
import ManageWorkingDays from './ManageWorkingDays/manageWorkingDaysList';
import WorkforceplanRoute from './workforceplan';
import AttendanceMonthList from '../MainPage/Main/Dashboard/AttendanceMonthList';
import UpComingEventList from '../MainPage/Main/Dashboard/UpComingEventList';
import ExpiryDocumentList from '../MainPage/Main/Dashboard/ExpiryDocumentList';
import MissingInfoList from '../MainPage/Main/Dashboard/MissingInfoList';
import WorkflowLanding from './Workflow/workflowLanding';
import AccessSetupLanding from './AccessSetup';
import SystemSetupLanding from './Settings';
import Assets from './Assets/AssetLanding.jsx';
import DevelopLanding from './Develop/index.jsx';
import DocumentRequestView from './ModuleSetup/DocumentRequest/view.jsx';
const CompanyAppRoute = ({ match }) => {
   return (
      <Switch>
         {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/admin`} /> */}

         <Route path={`${match.url}/timesheet`} component={TimesheetRoute} />
         <Route path={`${match.url}/manage-working-days`} component={ManageWorkingDays} />
         <Route path={`${match.url}/profile`} render={(props) => <Redirect to={`/app/company-app/employee/detail/0`}></Redirect>} />
         {/* <Route path={`${match.url}/leave`} component={Leave} /> */}
         <Route path={`${match.url}/support`} component={SupportModuleSetupRoute} />
         <Route path={`${match.url}/access-setup`} component={CompanyAccessSetupRoute} />
         <Route path={`${match.url}/module-setup`} component={CompanyModuleSetupRoute} />
         <Route path={`${match.url}/employee`} component={EmployeeModuleSetupRoute} />
         <Route path={`${match.url}/settings`} component={CompanySettingModuleSetupRoute} />
         <Route path={`${match.url}/attendance`} component={EmployeeAttendanceList} />
         <Route path={`${match.url}/entitlements`} component={EntitlementRoute} />
         <Route path={`${match.url}/team-approvals`} component={TeamApprovalSetupRoute} />
         <Route path={`${match.url}/payroll`} component={PayrollRoute} />
         <Route path={`${match.url}/report`} component={ReportRoute} />
         <Route path={`${match.url}/performance`} component={PerformanceRoute} />
         <Route path={`${match.url}/workforceplan`} component={WorkforceplanRoute} />
         <Route path={`${match.url}/projects`} component={ProjectsRoute} />
         <Route path={`${match.url}/Organisationchart`} component={OrganisationchartRoute} />
         <Route path={`${match.url}/document-request`} component={DocumentRequest} />
         <Route path={`${match.url}/Onboarding`} component={Onboarding} />

         <Route path={`${match.url}/Recognition`} component={RecognitionRoute} />
         <Route path={`${match.url}/RecognitionList`} component={RecognitionListIndex} />
         <Route path={`${match.url}/leave`} component={LeaveLanding} />
         <Route path={`${match.url}/hire`} component={HireLanding} />
         <Route path={`${match.url}/Roster`} component={RosterLanding} />
         <Route path={`${match.url}/Tasks`} component={TasksLanding} />
         <Route path={`${match.url}/TaskForm`} component={TasksForm} />
         <Route path={`${match.url}/LeaveTrackView`} component={LeaveTrackView} />
         <Route path={`${match.url}/modulesetup`} component={ModuleSetupLanding} />
         <Route path={`${match.url}/rewards`} component={Rewards} />
         <Route path={`${match.url}/survey`} component={SurveySetupRoute} /> 
         <Route path={`${match.url}/attendanceList`} component={AttendanceMonthList} />
         <Route path={`${match.url}/eventList`} component={UpComingEventList} />
         <Route path={`${match.url}/documentExpiry`} component={ExpiryDocumentList} />
         <Route path={`${match.url}/missing-information`} component={MissingInfoList} />
         <Route path={`${match.url}/workflow`} component={WorkflowLanding} />
         <Route path={`${match.url}/access`} component={AccessSetupLanding} />
         <Route path={`${match.url}/system`} component={SystemSetupLanding} />
         <Route path={`${match.url}/Assets`} component={Assets} />
         <Route path={`${match.url}/develop`} component={DevelopLanding} />
         <Route path={`${match.url}/employee-document-request`} component={DocumentRequestView} />

      </Switch>
   )
};

export default CompanyAppRoute;
