export const GENDER = {
    MALE: "MALE",
    FEMALE: "FEMALE"
}

export const MARITAL_STATUS = {
    SINGLE: "SINGLE",
    MARRIED: "MARRIED",
    DIVORCED: "DIVORCED",
    WIDOWED: "WIDOWED"
}

export const BLOOD_GROUP = {
    A_POSITIVE: "A_POSITIVE",
    A_NEGATIVE: "A_NEGATIVE",

    B_POSITIVE: "B_POSITIVE",
    B_NEGATIVE: "B_NEGATIVE",

    AB_POSITIVE: "AB_POSITIVE",
    AB_NEGATIVE: "AB_NEGATIVE",

    O_POSITIVE: "O_POSITIVE",
    O_NEGATIVE: "O_NEGATIVE",
}

export const SALARY_MODE = {
    ATTENDANCE: "ATTENDANCE",
    TIMESHEET: "TIMESHEET",
    THIRD_PARTY: "THIRD_PARTY"
}

export const STATUS = {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    RESIGNED: "RESIGNED",
    TERMINATED: "TERMINATED",
    INACTIVE: "INACTIVE"
}

export const PRIORITY = {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",

}


export const PROCESS_STATUS = {
    PROCESS_INITATED: "PROCESS_INITIATED",
    PROCESS_STARTED: "PROCESS_STARTED",
    PROCESS_STOPPED: "PROCESS_STOPPED",
}

export const TEMPLATE_TYPE_SUPER_ADMIN = {
    EMAIL: "EMAIL",
    PUSH_NOTIFICATION: "PUSH_NOTIFICATION"
}

export const TEMPLATE_TYPE = {
    DOCUMENT: "DOCUMENT"
}

export const PERMISSION_LEVEL = {
    SELF: "SELF",
    HIERARCHY: "HIERARCHY",
    ORGANIZATION: "ORGANIZATION",
    NONE: "NONE",
}


export const PERMISSION_GROUP = {
    EMPLOYEE: "EMPLOYEE",
    ORGANIZATION: "ORGANIZATION"
}
export const ACTION = {
    VIEW: "VIEW",
    EDIT: "EDIT"
}
export const SURVEY_STATUS = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    STANDBY: "STANDBY",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
}
export const ANSWER_TYPE = {
    SINGLE_ANSWER: "SINGLE_ANSWER",
    MULTIPLE_ANSWER: "MULTIPLE_ANSWER",
    NUMBER_SCALE: "NUMBER_SCALE",
    SUBJECTIVE: "SUBJECTIVE",
}
export const PayrollType = {
    NORMAL: "NORMAL",
    UAE: "UAE",
    UAE02: "UAE02",
}

export const ImportType = {
    ATTENDANCE: "ATTENDANCE",
    JOB_TITLE: "JOB_TITLE",
    EARNINGS: "EARNINGS",
    DEDUCTIONS: "DEDUCTIONS",
    EMPLOYEES: "EMPLOYEES"
}