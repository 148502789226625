import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { getTitle, getUserType } from '../../../utility';
import 'react-toastify/dist/ReactToastify.css';
import { FormGroup } from 'reactstrap';
import { getPerformanceById, getOverallScoreList } from './service';
import { PerformanceReviewSchema } from './validation';
toast.configure();

export default class PerformanceReviewReportDetailsForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.match.params.id,
            performanceReview: {},
            overallScores: []

        }
        this.getData(this.state.id);
        this.getOverallScoreData();
    }
    getOverallScoreData() {
        getOverallScoreList().then(res => {
            if (res.status == "OK") {
                this.setState({
                    overallScores: res.data.list
                });
            } else {
                toast.error(res.message);
            }
        })
    }
    getScoreTotal = (objectiveGroup) => {
        let total = 0;
        objectiveGroup.employeePerformanceObjectiveEntity.map(objective => {
            objective.employeePerformanceObjectiveTaskEntity.map(task => {
                total += task.score;
            })
        })
        return total;

    }
    isScoreMatch = (score, objectiveGroups) => {
        let scoreTotal = this.getOverallScore(objectiveGroups);
        let status = scoreTotal >= score.scoreFrom && scoreTotal <= score.scoreTo;
        console.log(scoreTotal, score.scoreFrom, score.scoreTo, status);
        return !status;
    }
    getHow = (index) => {
        if (this.state.performanceReview && this.state.performanceReview.employeePerformanceImprovementList && this.state.performanceReview.employeePerformanceImprovementList.length > (index + 1)) {
            return this.state.performanceReview.employeePerformanceImprovementList.filter(improvement => improvement.indexId == index)[0].how;
        }
    }
    getCompetency = (index) => {
        if (this.state.performanceReview && this.state.performanceReview.employeePerformanceImprovementList && this.state.performanceReview.employeePerformanceImprovementList.length > (index + 1)) {
            return this.state.performanceReview.employeePerformanceImprovementList.filter(improvement => improvement.indexId == index)[0].competency;
        }
    }
    getArea = (index) => {
        if (this.state.performanceReview && this.state.performanceReview.employeePerformanceImprovementList && this.state.performanceReview.employeePerformanceImprovementList.length > (index + 1)) {
            return this.state.performanceReview.employeePerformanceImprovementList.filter(improvement => improvement.indexId == index)[0].area;
        }
    }
    getContribution = (objectiveGroup) => {
        let total = this.getScoreTotal(objectiveGroup);
        return total * objectiveGroup.weightage / 100;

    }
    getOverallScore = (objectiveGroups) => {
        let total = 0;
        let _ = objectiveGroups?.map(objectiveGroup => {
            let tmpTotal = this.getScoreTotal(objectiveGroup);
            total = total + (tmpTotal * objectiveGroup.weightage / 100);
        })
        return total;
    }
    getData(id) {
        getPerformanceById(id).then(res => {
            if (res.status == "OK") {
                this.setState({
                    performanceReview: res.data.performanceReview,
                });

            } else {
                toast.error(res.message);
            }
        })
    }
    render() {
        return (
            <div>
                <div className="page-wrapper">
                    <Helmet>
                        <title>Performance Report Details  | {getTitle()}</title>
                        <meta name="description" content="Performance Review Details" />
                    </Helmet>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h3 className="page-title">Performance Review : {this.state.performanceReview?.employee?.name}</h3>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/app/main/dashboard">Dashboard</a></li>
                                        <li className="breadcrumb-item active">Performance Review</li>
                                    </ul>
                                </div>
                            </div>
                            <Formik
                                enableReinitialize={true}
                                initialValues={this.state.performanceReview}
                                onSubmit={this.save}
                                validationSchema={PerformanceReviewSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    setSubmitting
                                }) => (

                                    <Form>
                                        <div className="card">
                                            <h4 style={{ textAlign: 'center', paddingTop: '20px' }}>EMPLOYEE - ANNUAL PERFORMANCE EVALUATION </h4>
                                            <section className="review-section professional-excellence mt-2" >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered review-table mb-0 table-p-5">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Name of Employee</td>
                                                                        <td>{this.state.performanceReview?.employee?.name}</td>
                                                                        <td>Staff No</td>
                                                                        <td>{this.state.performanceReview?.employeesId}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Employee’s Position</td>
                                                                        <td>{this.state.performanceReview?.employeeDesignation}</td>
                                                                        <td>Grade</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Department/ Section</td>
                                                                        <td colSpan={3}>{this.state.performanceReview?.employeeDepartment}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Date of Joining</td>
                                                                        <td>{this.state.performanceReview?.doj?.substring(0, 10)}</td>
                                                                        <td>Date in Current Role</td>
                                                                        <td>{this.state.performanceReview?.dateOfCurrentRole?.substring(0, 10)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Reports to (Name)</td>
                                                                        <td>{this.state.performanceReview?.reportingManagerName}</td>
                                                                        <td>Reports to (Job Title)</td>
                                                                        <td>{this.state.performanceReview?.reportingManagerDesignation}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>REVIEW PERIOD FROM</td>
                                                                        <td>{this.state.performanceReview?.fromDate}</td>
                                                                        <td>REVIEW PERIOD TO</td>
                                                                        <td>{this.state.performanceReview?.toDate}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className="review-section professional-excellence mt-2" >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered review-table mb-0 table-p-5">

                                                                <thead>
                                                                    <tr>
                                                                        <th>Rating</th>
                                                                        <th>Description</th>
                                                                        <th>Explanation</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>5</td>
                                                                        <td>Significantly Exceeds Expectations</td>
                                                                        <td>Performance Significantly exceeds all expectations</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td>Exceeds Expectations</td>
                                                                        <td>Performance Exceeds many expectations</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>Meets All Expectations</td>
                                                                        <td>Performance satisfactorily meets all expectations</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td>Meets Most Expectation</td>
                                                                        <td>Performance meets most but not all expectations.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Needs Improvement</td>
                                                                        <td>Performance falls short of several expectations</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            {this.state.performanceReview?.employeePerformanceObjectiveGropList?.map((objectiveGroup, index) => {
                                                return <section className="review-section professional-excellence mt-2" key={index}>
                                                    <div className="review-header text-center col-12">
                                                        <div className="row">
                                                            <h4 className="review-title col-8">Section  – Performance Management for "{objectiveGroup.name}"</h4>
                                                            <div className="form-group col-4 mb-0">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">Group Weightage %</span>
                                                                    </div>
                                                                    <input readOnly tabIndex="-1" defaultValue={objectiveGroup.weightage} className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered review-table mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ width: '50px' }}>#</th>
                                                                            <th>Objectives</th>
                                                                            <th>Achievements</th>
                                                                            <th style={{ width: '100px' }}>Weightage %</th>
                                                                            <th style={{ width: '100px' }}>Employee Rating</th>
                                                                            <th style={{ width: '100px' }}>Reviewer Rating</th>
                                                                            <th style={{ width: '100px' }}>Score</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            objectiveGroup.employeePerformanceObjectiveEntity.map((objective, index) => {
                                                                                return <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td className='pre-wrap'>{objective.name}</td>
                                                                                    {
                                                                                        objective.employeePerformanceObjectiveTaskEntity.map((task, index) => {
                                                                                            return <><td className='pre-wrap'><span>{task.name}</span></td>
                                                                                                <td><input tabIndex="-1" type="number" defaultValue={task.weightage} className="form-control" readOnly /></td>
                                                                                                <td><input type="number" tabIndex="-1" readOnly defaultValue={task.selfRating} className="form-control" /></td>
                                                                                                <td><input type="number" defaultValue={task.managerRating} readOnly className="form-control" /></td>
                                                                                                <td><input type="number" defaultValue={task.score} readOnly className="form-control" /></td>
                                                                                            </>

                                                                                        })
                                                                                    }

                                                                                </tr>

                                                                            })

                                                                        }
                                                                        <tr>
                                                                            <td colSpan={6} className='text-right'>Total Score</td>
                                                                            <td>{this.getScoreTotal(objectiveGroup)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            })}

                                            <section className="review-section professional-excellence mt-2" >
                                                <div className="row">
                                                    <h4 className="review-title text-center col-8">Section  –  Combined Assessment</h4>
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered review-table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Objective</td>
                                                                        <td>Score</td>
                                                                        <td>Weight</td>
                                                                        <td>Contribution</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.performanceReview?.employeePerformanceObjectiveGropList?.map((objectiveGroup, index) => {
                                                                        return <tr>
                                                                            <td>{objectiveGroup.name}</td>
                                                                            <td>{this.getScoreTotal(objectiveGroup)}</td>
                                                                            <td>{objectiveGroup.weightage}</td>
                                                                            <td>{this.getContribution(objectiveGroup)}</td>
                                                                        </tr>
                                                                    })}
                                                                    <tr>
                                                                        <td className='text-right' colSpan={3}>Overall Score</td>
                                                                        <td>{this.getOverallScore(this.state.performanceReview?.employeePerformanceObjectiveGropList)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="review-section professional-excellence mt-2" >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered review-table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <td className='text-center' colSpan={3}>OVERAL SCORE</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.overallScores && this.state.overallScores?.map((score, index) => {
                                                                        return <tr>
                                                                            <td className='text-center'><i className="fa fa-2x fa-check text-success" hidden={this.isScoreMatch(score, this.state.performanceReview?.employeePerformanceObjectiveGropList)} ></i></td>
                                                                            <td>{score.name}</td>
                                                                            <td>{score.scoreFrom} - {score.scoreTo}</td>
                                                                        </tr>
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered review-table mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={2}> Comments by Line Manager</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Name: {this.state.performanceReview?.reportingManagerName} </td>

                                                                        <td> Job Title:{this.state.performanceReview?.reportingManagerDesignation}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='pre-wrap' colSpan={2}> {this.state.performanceReview?.reviewerComment}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Signed:</td>
                                                                        <td> Date: {this.state.performanceReview?.submitedByReviewerOn?.substring(0, 10)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2}> Comments by Employee being Appraised</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='pre-wrap' colSpan={2}> {this.state.performanceReview?.employeeComment}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Signed:</td>
                                                                        <td> Date: {this.state.performanceReview?.submitedByEmployeeOn?.substring(0, 10)}</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className="review-section professional-excellence mt-2">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h3 className="review-title text-center">Section – Recommended Development Plan for the New Year</h3>
                                                        <div className='pre-wrap p-2'>A more detailed Personal Development Plan should come out of this section to outline more on the “How”, “By When”, “Success Factors”, etc.  The Training Department will need to work closely with the Line Manager to meet these requirements</div>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered review-table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Area that Needs Improvement</th>
                                                                        <th>Specific Competency (Knowledge, Skill, Ability)</th>
                                                                        <th>How *</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody id='tabl'>
                                                                    <tr>
                                                                        <td><input title={this.getArea(0)} defaultValue={this.getArea(0)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getCompetency(0)} defaultValue={this.getCompetency(0)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getHow(0)} defaultValue={this.getHow(0)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input title={this.getArea(1)} defaultValue={this.getArea(1)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getCompetency(1)} defaultValue={this.getCompetency(1)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getHow(1)} defaultValue={this.getHow(1)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input title={this.getArea(2)} defaultValue={this.getArea(2)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getCompetency(2)} defaultValue={this.getCompetency(2)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getHow(2)} defaultValue={this.getHow(2)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input title={this.getArea(3)} defaultValue={this.getArea(3)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getCompetency(3)} defaultValue={this.getCompetency(3)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getHow(3)} defaultValue={this.getHow(3)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input title={this.getArea(4)} defaultValue={this.getArea(4)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getCompetency(4)} defaultValue={this.getCompetency(4)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                        <td><input title={this.getHow(4)} defaultValue={this.getHow(4)} disabled={true} type="text" maxLength={250} className='form-control' /></td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan={3} className='pre-wrap'>* HOW: Training & Development can include aswaaq Training courses, external training courses, e-learning, development workshops, on-the-job training, coaching and mentoring, job rotation, special assignments, secondment, etc.</td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
