import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { SafeAnchor } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormGroup } from 'reactstrap';
import { updateStatus } from './service';
import { Button, Stack } from '@mui/material';
//import { LeaveSchema } from './validation';
toast.configure();

export default class TimesheetAction extends Component {
    constructor(props) {
        super(props)

        this.state = {
            approvedHours: props.timesheet.approvedHours || 0,
            timesheet: props.timesheet || {
                id: 0,
                employeeId: props.employeeId,
                approvedHours:props.approvedHours||0, 
            },
            approve : props.timesheet.approvalStatus === 'APPROVED'? true :null ,
            // status:props.status||"APPROVED",
        } 
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.timesheet && nextProps.timesheet != prevState.timesheet) {
            return ({ timesheet: nextProps.timesheet })
        } else if (!nextProps.timesheet) {
            return ({
                timesheet: {
                    id: 0,
                    employeeId: nextProps.employeeId,
                }
            })
        }

        return null;
    }
    updateStatus = (id , status) => {
        let {approvedHours} = this.state;
        if(approvedHours == 0 && status != "REJECTED"){
            toast.error("Please Enter Approved Hours.");
        } else{
        updateStatus(id, this.state.approvedHours, status).then(res => {
            if (res.status == "OK") {
                toast.success(res.message);
                this.props.updateList(res.data);
            } else {
                toast.error(res.message);
            }

        }).catch(err => {
            console.error(err);
            toast.error("Error while updating status");
        })
    }
    };

    approveHours = (status) => {
        if(status === "Approve")
        {
            this.setState({ approve: true, })
        }else{
            this.setState({ approve: false, })
        }
    }
    render() {
        const { timesheet } = this.state;
        return (
            <div>
                {timesheet && 
                    <div>
                    <div className="row"> 
                        <div className="col-md-4">
                            <FormGroup>
                                <label>Employee</label>
                                <input name="employeeName" className="form-control" value={timesheet.employeeName}/>
                            </FormGroup>
                        </div>

                        <div className="col-md-4">
                            <FormGroup>
                                <label>Date</label>
                                <input name="employeeName" className="form-control" value={timesheet.date}/>
                            </FormGroup>
                        </div>

                        <div className="col-md-4">
                            <FormGroup>
                                <label>Hours</label>
                                <input name="employeeName" className="form-control" value={timesheet.hours}/>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='row'>
                    <div className="col-md-2">
                            <FormGroup>
                                <Button sx={{ textTransform: 'none' }} size="small" onClick={() => this.approveHours("Approve")}
                                 variant="contained" color="success">
                                    Approve
                                </Button>
                            </FormGroup>
                        </div>
                        <div className="col-md-2">
                            <FormGroup>
                                <Button sx={{ textTransform: 'none' }} size="small" onClick={() => {
                                    this.updateStatus(timesheet.id ,  "REJECTED");
                                }} variant="contained" color="error">
                                    Reject
                                </Button>  
                          </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-8">
                            <FormGroup>
                                <label>Status
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="form-control">
                                   <label className='mr-2'> <input name="status" type="radio" value="1" checked /> Approve</label>
                                   <label> <input name="status" type="radio" value="2" onChange={(e) => {
                                        this.setState({
                                            status: e.target.checked?"REJECTED":"APPROVED",
                                            approvedHours:e.target.checked?0:this.state.approvedHours
                                        });
                                        }}/> Reject</label>
                                    
                                </div>
                            </FormGroup>
                        </div> */}
                        <div className="col-md-4">
                            {console.log('cell : ==', this.state.status)}
                            {this.state.approve && <FormGroup>
                                <label> Approved Hours
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input name="approvedhours" type="number" className="form-control" defaultValue={timesheet.approvedHours}
                                  onChange={(e) => {
                                    this.setState({
                                        approvedHours: e.target.value
                                    });
                                }}/>
                            </FormGroup>}
                        </div>
                    </div>
                        <hr />
                        <Stack direction="row" spacing={1}>
                        {this.state.approve === true &&
                            <Button sx={{ textTransform: 'none' }} size="small" onClick={() => {
                                this.updateStatus(timesheet.id , "APPROVED");
                            }} variant="contained" style={{background:'orange'}}>
                                Submit
                            </Button>
                        }
                        </Stack>
                </div>}
            </div>
        )
    }
}
