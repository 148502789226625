import { deleteWithAuth, getWithAuth, patchWithAuth, postWithAuth } from "../../../HttpRequest";
import { getPaginationQueryString } from "../../../utility";

const servicePath = '/payslip';

export function getPayslips(salaryMonth, searchText, pageNumber, pageSize, sort) {
    let path = `${servicePath}?salaryMonth=${salaryMonth}&${getPaginationQueryString(searchText, pageNumber, pageSize, sort)}`;
    return getWithAuth(path).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}
export function generatePayslips(salaryMonth) {
    let path = `${servicePath}?salaryMonth=${salaryMonth}`;
    return postWithAuth(path).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}
export function deletePayslip(payslipId) {
    let path = servicePath + "?id=" + encodeURIComponent(payslipId);
    return deleteWithAuth(path).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}

export function updatePayslipStatus(paySlipIds, status) {
    let path = servicePath + "/status?status=" + status;
    return patchWithAuth(path, paySlipIds).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}
export function updateAllPayslipStatus(monthYear, status) {
    let path = servicePath + "/status/all?monthYear=" + monthYear + "&status=" + status;
    return patchWithAuth(path).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}


export function getPayrollCloseMonths(closeYear) {
    let path = `${servicePath}/closed?year=${closeYear}`;
    return getWithAuth(path).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}

export function closePayrollMonth(monthYear) {
    let path = `${servicePath}/close?monthYear=${monthYear}`;
    return patchWithAuth(path).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}

export function getMonthlyData(salaryMonth, q) {
    let path = `${servicePath}/monthly-data?salaryMonth=${salaryMonth}&q=${q}`;
    return getWithAuth(path).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}

export function getUAE02Payslip(employeeId, salaryMonth) {
    let path = `${servicePath}/get-uae02?salaryMonth=${salaryMonth}&employeeId=${employeeId}`;
    return getWithAuth(path).then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        return Promise.reject(err);
    });
}

