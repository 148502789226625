import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormGroup } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { convertToUTC, toUTCCalendarTime } from '../../../utility';
import { saveShifts } from './service';
import { ShiftsSchema } from './validation';
toast.configure();

export default class Shifts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shifts: props.shifts || {
                id: 0,
                shiftname: "",
                shiftcodename: "",
                description: "",
                shiftstarttime: "",
                shiftendtime: "",
                breaktime: 0
            }
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.shifts && nextProps.shifts != prevState.shifts) {
            return ({ shifts: nextProps.shifts })
        } else if (!nextProps.shifts) {
            return prevState.shifts || ({
                shifts: {
                    id: 0,
                    shiftname: "",
                    shiftcodename: "",
                    description: "",
                    shiftstarttime: "",
                    shiftendtime: "",
                    breaktime: 0
                }
            })
        }
        return null;
    }
    updateTime = () => {
        let currentTime = new Date();
        let currentTimeMillis = currentTime.getTime();
        let currentUTCTime = currentTime.toUTCString();
        let hour = currentTime.getUTCHours();

        console.log("Current time in milliseconds: " + currentTimeMillis);
        console.log("Current time in UTC: " + currentUTCTime);
        console.log("Current time in hour: " + hour);

    }

    save = (data, action) => {
        let tmpData = {};
        tmpData.id = data.id;
        tmpData.shiftstarttime = toUTCCalendarTime(data.shiftstarttime);
        tmpData.shiftendtime = toUTCCalendarTime(data.shiftendtime);
        tmpData.description = data.description;
        tmpData.shiftcodename = data.shiftcodename;
        tmpData.shiftname = data.shiftname;
        tmpData.breaktime = data.breaktime;
        action.setSubmitting(true);
        saveShifts(tmpData).then(res => {
            if (res.status == "OK") {
                toast.success(res.message);
                this.props.updateList(res.data);
            } else {
                toast.error(res.message);
            }
            action.setSubmitting(false)
        }).catch(err => {
            toast.error("Error while saving Shifts");
            action.setSubmitting(false);
        })
    }
    render() {
        return (
            <div >

                {/* Page Content */}

                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.shifts}
                    onSubmit={this.save}
                    validationSchema={ShiftsSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setSubmitting
                        /* and other goodies */
                    }) => (
                        <Form autoComplete="off">
                            <div className="row" style={{ paddingTop: "25px" }} >
                                <div className="col-md-6">
                                    <FormGroup>
                                        <label>Shift Name
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field name="shiftname" className="form-control" placeholder="Shift Name"></Field>
                                        <ErrorMessage name="shiftname">
                                            {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </FormGroup>
                                </div>
                                <div className="col-md-6">
                                    <FormGroup>
                                        <label>Shift Code Name
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field name="shiftcodename" className="form-control" placeholder="Shift Code Name"></Field>
                                        <ErrorMessage name="shiftcodename">
                                            {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-12">
                                    <FormGroup>
                                        <label>Description</label>
                                        <Field name="description" className="form-control" placeholder="Description" component="textarea" rows="3"></Field>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label>Shift Start Time
                                            <span style={{ color: "red" }}>*</span>
                                        </label> &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Field type="time" name="shiftstarttime" placeholder="Select time" className="form-control"></Field>
                                        <ErrorMessage name="shiftstarttime">
                                            {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label>Shift End Time
                                            <span style={{ color: "red" }}>*</span>
                                        </label>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Field type="time" name="shiftendtime" placeholder="Select time" className="form-control"></Field>
                                        <ErrorMessage name="shiftendtime">
                                            {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label>Break Time (In Minutes)</label>
                                        <Field name="breaktime" className="form-control" placeholder="Break Time(In Minutes)" />
                                    </FormGroup>
                                </div>
                            </div>
                            < div>
                                <input type="submit" className="btn btn-primary" value={this.state.shifts.id > 0 ? "Update" : "Create"} /> </div>

                        </Form>
                    )
                    }
                </Formik>
            </div>


        )
    }
}
