import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, withRouter } from 'react-router-dom';
import { User, Avatar_19, Avatar_07, Avatar_06, Avatar_14 } from '../../../Entryfile/imagepath.jsx'
import { getUserName, getTitle, getPrimaryGradientColor, getPrimaryColor, getReadableDate,verifyViewPermission, verifyOrgLevelViewPermission } from '../../../utility';
import {
  BarChart, Bar, Cell, ResponsiveContainer,
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import "../../index.css"
import { getCompanyAdminDashboardDetail } from './service.jsx';
import AccessDenied from './AccessDenied.jsx';

class CompanyAnalyticsDashboard extends Component {

    constructor(props) {
      super(props);
      this.state = {
        dashboardData: {}
      };
    }
    componentDidMount() {
      this.getDashboardData();
    }
    getDashboardData = () => {
      getCompanyAdminDashboardDetail().then(res => {
        this.setState({ dashboardData: res.data }, () => {
        });
      });
    }
    getdepartmentdata() {
      let departmentdata = [];
      if (this.state.dashboardData.employeeCountByDepartment) {
        departmentdata = this.state.dashboardData.employeeCountByDepartment.map(item => {
          return {
            Department: item.valuey, "Total Employees": item.valuex
          }
        })
      }
      return departmentdata;
    }
    getGenderdata() {
      let genderdata = [];
      if (this.state.dashboardData.employeeCountByDepartment) {
        genderdata = this.state.dashboardData.employeeCountByGender.map(item => {
          return {
            Gender: item.valuey, "Total Employees": item.valuex
          }
        })
      }
      return genderdata;
    }
    getNationalitydata() {
      let nationalitydata = [];
      if (this.state.dashboardData.employeeCountByDepartment) {
        nationalitydata = this.state.dashboardData.employeeCountByNationality.map(item => {
          return {
            Nationality: item.valuey, "Total Employees": item.valuex
          }
        })
      }
      return nationalitydata;
    }
    getCountByMonthdata() {
      let countByMonthdata = [];
      if (this.state.dashboardData.employeeCountByDepartment) {
        countByMonthdata = this.state.dashboardData.employeeCountByMonth.map(item => {
          return {
            Month: item.valuey, "Total Employees": item.valuex
          }
        })
      }
      return countByMonthdata;
    }
    UNSAFE_componentWillMount() {
      let firstload = localStorage.getItem("firstload")
      if (firstload === "true") {
        setTimeout(function () {
          window.location.reload(1)
          localStorage.removeItem("firstload")
        }, 1000)
      }
    }
  
    render() {
      const { dashboardData, loadSocialShare } = this.state;
      return (
  
        <div className="insidePageDiv">
          <Helmet>
            <title>Analytics Dashboard</title>
            <meta name="description" content="Dashboard" />
          </Helmet>
          {/* Page Content */}
          <div className="page-containerDocList content container-fluid">
          <div className="tablePage-header">
            <div className="row pageTitle-section">
                <div className="col-sm-6">
                  <h3 className="tablePage-title">Analytics Dashboard</h3>
                  <ul hidden className="breadcrumb">
                    <li className="breadcrumb-item active"></li>
                  </ul>
                </div> 
              </div>
            </div>
            {(verifyOrgLevelViewPermission("Analytics Report")) &&<>
            <div className="tab-content pt-0">
              <div className="tab-pane active" id="step1">
                {/* /Page Header */}
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                      <div className="card-body">
                        <span className="dash-widget-icon"><i className="fa fa-building" /></span>
                        <div className="dash-widget-info">
                          <h3>{this.state && this.state.dashboardData && this.state.dashboardData.topCounts && this.state.dashboardData.topCounts.Branches ? this.state.dashboardData.topCounts.Branches : 0}</h3>
                          <span>Locations</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                      <div className="card-body">
                        <span className="dash-widget-icon"><i className="fa fa-cubes" /></span>
                        <div className="dash-widget-info">
                          <h3>{this.state && this.state.dashboardData && this.state.dashboardData.topCounts && this.state.dashboardData.topCounts.Departments ? this.state.dashboardData.topCounts.Departments : 0}</h3>
                          <span>Departments</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                      <div className="card-body">
                        <span className="dash-widget-icon"><i className="fa fa-diamond" /></span>
                        <div className="dash-widget-info">
                          <h3>{this.state && this.state.dashboardData && this.state.dashboardData.topCounts && this.state.dashboardData.topCounts.JobTitles ? this.state.dashboardData.topCounts.JobTitles : 0}</h3>
                          <span>Job Titles</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                      <div className="card-body">
                        <span className="dash-widget-icon"><i className="fa fa-user" /></span>
                        <div className="dash-widget-info">
                          <h3>{this.state && this.state.dashboardData && this.state.dashboardData.topCounts && this.state.dashboardData.topCounts.Employees ? this.state.dashboardData.topCounts.Employees : 0}</h3>
                          <span>Employees</span>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">Employees by division</h3>
                        {/* <div id="bar-charts" /> */
                        }
                        <ResponsiveContainer width='100%' height={300}>
                          <BarChart
  
                            data={this.getdepartmentdata()}
                            margin={{
                              top: 5, right: 5, left: 5, bottom: 5,
                            }}
                          >
                            <CartesianGrid />
                            <XAxis dataKey="Department" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Total Employees" fill={getPrimaryColor()} />
                          </BarChart>
                        </ResponsiveContainer>
  
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">Employees by Gender</h3>
                        {/* <div id="bar-charts" /> */
                        }
                        <ResponsiveContainer width='100%' height={300}>
                          <BarChart
  
                            data={this.getGenderdata()}
                            margin={{
                              top: 5, right: 5, left: 5, bottom: 5,
                            }}
                          >
                            <CartesianGrid />
                            <XAxis dataKey="Gender" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Total Employees" fill={getPrimaryGradientColor()} />
                          </BarChart>
                        </ResponsiveContainer>
  
                      </div>
                    </div>
                  </div>
  
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">Employees by Joining Month</h3>
                        {/* <div id="bar-charts" /> */
                        }
                        <ResponsiveContainer width='100%' height={300}>
                          <BarChart
  
                            data={this.getCountByMonthdata()}
                            margin={{
                              top: 5, right: 5, left: 5, bottom: 5,
                            }}
                          >
                            <CartesianGrid />
                            <XAxis dataKey="Month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Total Employees" fill={getPrimaryGradientColor()} />
                          </BarChart>
                        </ResponsiveContainer>
  
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">Employees by Nationality</h3>
                        {/* <div id="bar-charts" /> */
                        }
                        <ResponsiveContainer width='100%' height={300}>
                          <BarChart
  
                            data={this.getNationalitydata()}
                            margin={{
                              top: 5, right: 5, left: 5, bottom: 5,
                            }}
                          >
                            <CartesianGrid />
                            <XAxis dataKey="Nationality" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Total Employees" fill={getPrimaryColor()} />
                          </BarChart>
                        </ResponsiveContainer>
  
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">Ticket Status (Company)</h3>
                        <ResponsiveContainer width='100%' height={100}>
                          <table>
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dashboardData && dashboardData.ticketCountByStatusCompany?.length > 0 && dashboardData.ticketCountByStatusCompany.map((ticket, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <span className="badge badge-primary">{ticket.name}</span>
                                    </td>
                                    <td>
                                      {ticket.id}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
  
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">Ticket Status (Self)</h3>
                        <ResponsiveContainer width='100%' height={100}>
                          <table>
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dashboardData && dashboardData.ticketCountByStatusSelf?.length > 0 && dashboardData.ticketCountByStatusSelf.map((ticket, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <span className="badge badge-primary">{ticket.name}</span>
                                    </td>
                                    <td>
                                      {ticket.id}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
  
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title text-center">To Do List</h3>
                        <ResponsiveContainer width='100%' height={200}>
                          <div>
                            {/* {toDos=> task,description and actionLink } */}
                            {dashboardData && dashboardData.toDos?.length > 0 && dashboardData.toDos.map((toDo, index) => {
                              return (
                                <div key={index} className="border-bottom">
  
                                  <Link to={toDo.actionLink}>
                                    <label className='m-0'>
                                      <i className='fa fa-check'></i>&nbsp;
                                      {toDo.task}</label>
                                  </Link>
                                  <br />
                                  <small>{toDo.description}</small>
                                </div>
                              )
                            })}
                          </div>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
  
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title text-center">Announcement</h3>
                        <ResponsiveContainer width='100%' height={200}>
                          <div>
                            {dashboardData.announcements?.map((item, index) => {
                              return (
                                <div key={index} className="border-bottom">
                                  
                                  <h5><i><small>{getReadableDate(new Date(item.validFrom))} - {getReadableDate(new Date(item.validTill))}</small></i>
                                  &nbsp;{item.title}</h5>
                                  <p><small>{item.description}</small></p>
                                </div>
                              )
                            })}
                            {(!dashboardData.announcements || dashboardData.announcements?.length == 0) && <span>No Announcement</span>}
                          </div>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">Last 30 Days Attendance</h3>
                        {/* <div id="bar-charts" /> */
                        }
                        <ResponsiveContainer height={300}>
                          <BarChart
                            width={500}
                            data={this.state.dashboardData?.last30DaysAttendance}
                            margin={{
                              top: 5, right: 5, left: 5, bottom: 5,
                            }}
                          >
                            <CartesianGrid />
                            <XAxis angle={90} interval={0} dy={50} height={120} dataKey="day" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar barSize={20} dataKey="presentCount" stackId="a" fill="#82ca9d" />
                            <Bar barSize={20} dataKey="absentCount" stackId="a" fill={getPrimaryColor()} />
                          </BarChart>
                        </ResponsiveContainer>
  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               
            </div>
            </>}
            {!verifyOrgLevelViewPermission("Analytics Report") && <AccessDenied></AccessDenied>}
          </div>
          {/* /Page Content */}
        </div>
      );
    }
  }
  
  export default withRouter(CompanyAnalyticsDashboard);