import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormGroup } from 'reactstrap';
import { saveLeaveType } from './service';
import { LeaveTypeSchema } from './validation';
toast.configure();

export default class LeaveTypeForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            locationId: props.locationId || 0,
            locationName: props.locationName || "",
            leaveType: props.leaveType || {
                id: 0,
                title: "",
                days: 1,
                paid: true,
                accrual: true,
                encashment: true,
                attachmentRequired: true,
                applicableGender: null,
                branchId: props.locationId,
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.leaveType && nextProps.leaveType != prevState.leaveType) {
            return ({ leaveType: nextProps.leaveType })
        } else if (!nextProps.leaveType) {

            return prevState.leaveType || ({
                leaveType: {
                    id: 0,
                    title: "",
                    days: 1,
                    paid: true,
                    accrual: true,
                    encashment: true,
                    attachmentRequired: true,
                    applicableGender: null,
                    branchId: prevState.locationId,
                    showOnDashboard: true,
                }
            })
        }
        return null;
    }
    save = (data, action) => {
        action.setSubmitting(true);
        saveLeaveType(data).then(res => {
            if (res.status == "OK") {
                toast.success(res.message);
                this.props.updateList(res.data);
            } else {
                toast.error(res.message);
            }
            action.setSubmitting(false)
        }).catch(err => {
            toast.error("Error while saving Leave Type");
            action.setSubmitting(false);
        })
    }
    render() {
        return (
            <div>

                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.leaveType}
                    onSubmit={this.save}
                    validationSchema={LeaveTypeSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setSubmitting
                        /* and other goodies */
                    }) => (
                        <Form>
                            <FormGroup>
                                <label>Location
                                </label><p>
                                    <span style={{ fontWeight: 'bold' }}>{this.state.locationName}</span></p>

                            </FormGroup>
                            <FormGroup>
                                <label>Title
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field name="title" className="form-control"></Field>
                                <ErrorMessage name="title">
                                    {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                </ErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <label>Days
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field name="days" className="form-control"></Field>
                                <ErrorMessage name="days">
                                    {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                </ErrorMessage>
                            </FormGroup>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormGroup>
                                        <div type="checkbox" name="paid" onClick={e => {
                                            let { leaveType } = this.state;
                                            leaveType.paid = !leaveType.paid;
                                            setFieldValue("paid", leaveType.paid);
                                            this.setState({
                                                leaveType
                                            });
                                        }} >
                                            <label>Paid</label><br />
                                            <i className={`fa fa-2x ${this.state.leaveType
                                                && this.state.leaveType.paid
                                                ? 'fa-toggle-on text-success' :
                                                'fa fa-toggle-off text-danger'}`}></i>
                                        </div>

                                    </FormGroup>
                                </div> <div className="col-md-3">
                                    <FormGroup>
                                        <div type="checkbox" name="accrual" onClick={e => {
                                            let { leaveType } = this.state;
                                            leaveType.accrual = !leaveType.accrual;
                                            setFieldValue("accrual", leaveType.accrual);
                                            this.setState({
                                                leaveType
                                            });
                                        }} >
                                            <label>Accrual</label><br />
                                            <i className={`fa fa-2x ${this.state.leaveType
                                                && this.state.leaveType.accrual
                                                ? 'fa-toggle-on text-success' :
                                                'fa fa-toggle-off text-danger'}`}></i>
                                        </div>
                                    </FormGroup>
                                </div> <div className="col-md-3">
                                    <FormGroup>
                                        <div type="checkbox" name="accrual" onClick={e => {
                                            let { leaveType } = this.state;
                                            leaveType.encashment = !leaveType.encashment;
                                            setFieldValue("encashment", leaveType.encashment);
                                            this.setState({
                                                leaveType
                                            });
                                        }} >
                                            <label>Encashment</label><br />
                                            <i className={`fa fa-2x ${this.state.leaveType
                                                && this.state.leaveType.encashment
                                                ? 'fa-toggle-on text-success' :
                                                'fa fa-toggle-off text-danger'}`}></i>
                                        </div>
                                    </FormGroup>
                                </div><div className="col-md-3">
                                    <FormGroup>
                                        <div type="checkbox" name="accrual" onClick={e => {
                                            let { leaveType } = this.state;
                                            leaveType.attachmentRequired = !leaveType.attachmentRequired;
                                            setFieldValue("attachmentRequired", leaveType.attachmentRequired);
                                            this.setState({
                                                leaveType
                                            });
                                        }} >
                                            <label>Attachment</label><br />
                                            <i className={`fa fa-2x ${this.state.leaveType
                                                && this.state.leaveType.attachmentRequired
                                                ? 'fa-toggle-on text-success' :
                                                'fa fa-toggle-off text-danger'}`}></i>
                                        </div>
                                    </FormGroup>
                                </div></div>
                            <FormGroup>
                                <div type="checkbox" name="showOnDashboard" onClick={e => {
                                    let { leaveType } = this.state;
                                    leaveType.showOnDashboard = !leaveType.showOnDashboard;
                                    setFieldValue("showOnDashboard", leaveType.showOnDashboard);
                                    this.setState({
                                        leaveType
                                    });
                                }} >
                                    <label>Show on Dashboard</label><br />
                                    <i className={`fa fa-2x ${this.state.leaveType
                                        && this.state.leaveType.showOnDashboard
                                        ? 'fa-toggle-on text-success' :
                                        'fa fa-toggle-off text-danger'}`}></i>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>Applicable To</label>
                                <select id="applicableGender" disabled={this.state.leaveType.id > 0} className="form-control" name="applicableGender"
                                    onChange={e => {
                                        setFieldValue("applicableGender", e.target.value)
                                    }}>
                                    <option value="">Select Applicable To</option>
                                    <option selected={this.state.leaveType && this.state.leaveType.applicableGender == 0} value="0">Male</option>
                                    <option selected={this.state.leaveType && this.state.leaveType.applicableGender == 1} value="1">Female</option>
                                    <option selected={this.state.leaveType && this.state.leaveType.applicableGender != 0 && this.state.leaveType.applicableGender != 1} value="2">All</option>
                                </select>
                            </FormGroup>
                            <input type="submit" className="btn btn-primary" value={this.state.leaveType.id > 0 ? "Update" : "Save"} />
                        </Form>
                    )
                    }
                </Formik>
            </div>
        )
    }
}
